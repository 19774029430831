import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Header from "../../../components/header/Header";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import {
  getToken,
  decodeRefreshToken,
  decodeDataToken,
} from "../../../helpers/token/refreshToken";
import { db } from "../../../firebase";
import { onValue, ref, update } from "firebase/database";
import { convertDate } from "../../../helpers/date/convertDate";
import { useTranslation } from "react-i18next";
import { generateOpenPayURL } from "../../../api/openpay/openpay";
import { getAdvanceCashOrder } from "../../../api/booking/advance";
import { buttonStyle } from "./style/PendingLater.style";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getEnterpriseEmployeeAddress } from "../../../api/users/enterprise/address/address";
import { getModels } from "../../../api/resources/model";
import {
  dateFormat,
  formatDateTime,
  timeFormat,
} from "../../../helpers/date/date";
import SnackBar from "../../../components/snackbar/SnackBar";

const HistoryQuoteLater = () => {
  const [booking, setBooking] = useState([]);
  const { t } = useTranslation();
  const dataToken = getToken("dttkn");
  const { enterprise_id, sub_role } = decodeDataToken(dataToken);
  const [listAdvance, setListAdvance] = useState([]);

  const [modelApi, setModelApi] = useState([]);

  const currency = { 1: "PEN", 2: "USD" };

  useEffect(() => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);
    const starCountRef = ref(db, "BookingBusiness/");

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, a.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };

        setBooking([]);
        setListAdvance([]);
        Object.values(data)
          .sort(compareByDate)
          .map(async (_booking) => {
            if (sub_role === "ADMIN") {
              if (
                _booking.booking_type != 1 &&
                Number(_booking.enterpriseId) === Number(enterprise_id) &&
                !_booking.cotizar
              ) {
                _booking.currency = currency[_booking.currency];
                _booking.fullorigin = _booking.origin.name;
                _booking.fulldestination = _booking.destination.name;
                setBooking((oldArray) => [...oldArray, _booking]);
              }
            } else if (
              _booking.booking_type != 1 &&
              Number(_booking.client) === uid &&
              !_booking.cotizar
            ) {
              _booking.currency = currency[_booking.currency];
              _booking.fullorigin = _booking.origin.name;
              _booking.fulldestination = _booking.destination.name;
              setBooking((oldArray) => [...oldArray, _booking]);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    console.log(booking);
    if (booking.length > 0) {
      const getOrderPay = async () => {
        setListAdvance([]);
        booking.map(async (item) => {
          const resp = await getAdvanceCashOrder(item.counterBooking);
          const data = resp.data.resp;
          if (data.length > 0) {
            const list = { orderNumber: item.counterBooking, data };
            setListAdvance((oldData) => [...oldData, list]);
          }
        });
      };

      getOrderPay();
    }
  }, [booking]);

  const model = ["", "Sedan", "SUV", "Minivan"];
  const [open, setOpen] = React.useState(false);
  const [uidX, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (booking) => {
    try {
      const dataToken = getToken("dttkn");
      const { name, lastname, phone, email } = decodeDataToken(dataToken);
      const orderID = `${booking.counterBooking}-${Date.now()}`;
      const redirectUrl = `https://business.taximundo.com/voucher/${booking.currency}`;
      // const redirectUrl = `http://localhost:3003/voucher/${booking.currency}`;

      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.advance),
        currency: booking.currency,
        description: `${booking.counterBooking}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(),
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await generateOpenPayURL(bookingOpenPay);
      if (response.data.message === "success") {
        const data = response.data.data;
        const url = response.data.data.payment_method.url;
        window.open(url, "_blank");
        const openpayData = {
          transaction_id: data.id,
          order_id: data.order_id,
          createBy: "B",
        };
        await update(
          ref(db, `BookingBusiness/${booking.uuid}/openpay`),
          openpayData
        );
      } else {
        alert("ERROR");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatClientName = (name) => {
    const nameSplit = name.split(" ");
    if (nameSplit.length === 2) {
      return name;
    }
    if (nameSplit.length === 3) {
      return `${nameSplit[0]} ${nameSplit[1]}`;
    }
    if (nameSplit.length > 3) {
      return `${nameSplit[0]} ${nameSplit[2]}`;
    }
    return name;
  };

  const [bookItem, setBookItem] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  const [dateEnd, setDateEnd] = useState();
  const [timeEnd, setTimeEnd] = useState();

  const [persons, setPersons] = useState(0);
  const [vehicle, setVehicle] = useState(1);
  const [note, setNote] = useState("");

  const [addressApi, setAddressApi] = useState([]);

  useEffect(() => {
    const getAddressEnterprise = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");

        if (dataToken != null && token != null) {
          const { enterprise_id } = decodeDataToken(dataToken);
          const uid = decodeRefreshToken(token);

          setAddressApi([]);

          const response = await getEnterpriseEmployeeAddress(
            token,
            uid,
            enterprise_id
          );
          console.log(response);
          setAddressApi(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModelApi(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
    getAddressEnterprise();
  }, []);

  const checkTime = () => {
    const today = new Date();
    const { day, month, year, hour, minute } = formatDateTime(
      date.$d ?? date,
      time.$d ?? time
    );

    const dateOrder = new Date(year, month, day, hour, minute, 0);
    const diffInMillisec = dateOrder.getTime() - today.getTime();
    const diffInMin = diffInMillisec / (1000 * 60);

    console.log(diffInMin);
    if (diffInMin <= 60) {
      snackBarDialog("TODA RESERVA DEBE SER MAYOR O IGUAL A 1 HORA", "error");
      return;
    }

    return true;
  };

  const updateBookingLater = async (e, item) => {
    e.preventDefault();
    try {
      if (checkTime()) {
        const refPath = `BookingBusiness/${item.uuid}`;
        const refDB = ref(db, refPath);

        let dateend = "";
        let timeend = "";
        if (item.booking_type == 3) {
          dateend = dateEnd.$d ? dateFormat(dateEnd.$d) : dateFormat(dateEnd);
          timeend = timeEnd.$d ? timeFormat(timeEnd.$d) : timeFormat(timeEnd);
        }

        const data = {
          origin: {
            lat: origin.lat ?? origin.latitude,
            lng: origin.lng ?? origin.longitude,
            name: origin.name,
          },
          destination: {
            lat: destination.lat ?? destination.latitude,
            lng: destination.lng ?? destination.longitude,
            name: destination.name,
          },
          date: date.$d ? dateFormat(date.$d) : dateFormat(date),
          time: time.$d ? timeFormat(time.$d) : timeFormat(time),
          dateEnd: dateend,
          timeEnd: timeend,
          persons: Number(persons) ?? 1,
          vehicle: Number(vehicle) ?? 1,
          note: note.toUpperCase() ?? "",
        };
        await update(refDB, data);
        snackBarDialog("DATOS ACTUALIZADOS");
        handleCloseEdit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatterTimePicker = (date, time) => {
    const [day, month, year] = date.split("/");
    const [hourMin, amPm] = time.split(" ");
    const [hour, minute] = hourMin.split(":");
    const realHour =
      amPm == "AM" ? hour : hour == 12 ? hour : parseInt(hour) + 12;
    return new Date(year, month - 1, day, realHour, minute);
  };

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("booking_pending")} />
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} sm={6}>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    TM-{booking.counterBooking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={booking.booking_type === 3 ? 3 : 6}
                  textAlign={"end"}
                >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {booking.date} - {booking.time}
                  </Typography>
                </Grid>
                {booking.booking_type === 3 && (
                  <Grid item xs={12} sm={3} textAlign={"end"}>
                    <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                      {booking.dateEnd} - {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                {booking.driver ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Conductor: {booking.driver.name} {booking.driver.lastname}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Vehicle: {booking.driver.brand} {booking.driver.model} -
                      {booking.driver.plate}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  {sub_role === "ADMIN" && (
                    <Typography
                      color={"primary"}
                      sx={{ fontSize: "13px", fontWeight: "bold" }}
                    >
                      {formatClientName(booking.clientName)}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>

                {listAdvance.map((item, index) => {
                  console.log("item", item);
                  const check =
                    Number(booking.counterBooking) == Number(item.orderNumber);

                  if (check) {
                    return (
                      <div key={index}>
                        {item.data.map((adv, idx) => {
                          return (
                            <div
                              key={idx}
                              style={{ width: "100%", fontWeight: "bold" }}
                            >
                              {idx + 1}.- {adv.currency} {adv.amount}{" "}
                              {adv.status} {adv.operation_date}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p style={buttonStyle}>
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  {booking.payment_type == 1 && (
                    <p style={buttonStyle}>
                      {booking.currency} {booking.total}
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{ fontWeight: "bold", background: "#128C7E" }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {booking.total > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>
                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => setOpen(false)}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}

                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{ ...buttonStyle, cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(booking);
                      setBookItem(booking);
                      setDate(dayjs(booking.date, "DD/MM/YYYY").toDate());
                      setTime(formatterTimePicker(booking.date, booking.time));

                      if (booking.booking_type == 3) {
                        setDateEnd(
                          dayjs(booking.dateEnd, "DD/MM/YYYY").toDate()
                        );
                        setTimeEnd(
                          formatterTimePicker(booking.dateEnd, booking.timeEnd)
                        );
                      }

                      setOrigin(booking.origin);
                      setDestination(booking.destination);
                      setPersons(booking.persons);
                      setVehicle(booking.vehicle);
                      setNote(booking.note);
                      handleOpenEdit();
                    }}
                  >
                    <CreateOutlinedIcon />
                    EDITAR
                  </p>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("NO HAY RESERVAS PENDIENTES")}</div>
      )}

      <Modal open={openEdit} onClose={handleCloseEdit}>
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              {addressApi ? (
                <Autocomplete
                  value={origin}
                  disablePortal
                  name="addressOrigin"
                  id="combo-box-origin"
                  onChange={(e, newValue) => setOrigin(newValue)}
                  options={addressApi}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField {...params} label={t("origin")} />
                  )}
                />
              ) : (
                <TextField
                  id="origin"
                  label="ORIGEN"
                  fullWidth
                  variant="outlined"
                  value={bookItem?.origin?.name}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              {addressApi ? (
                <Autocomplete
                  value={destination}
                  disablePortal
                  name="addressDestination"
                  id="combo-box-destination"
                  onChange={(e, newValue) => setDestination(newValue)}
                  options={addressApi}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField {...params} label={t("destination")} />
                  )}
                />
              ) : (
                <TextField
                  id="destination"
                  label="DESTINO"
                  fullWidth
                  variant="outlined"
                  value={bookItem?.destination?.name}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <MobileDatePicker
                    label={t("date")}
                    inputFormat="DD/MM/YYYY"
                    name="date"
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <MobileTimePicker
                    label={t("time")}
                    value={time}
                    name="timeEnd"
                    onChange={(newValue) => setTime(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            {bookItem?.booking_type == 3 && (
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <MobileDatePicker
                      label={t("date")}
                      inputFormat="DD/MM/YYYY"
                      name="dateEnd"
                      value={dateEnd}
                      onChange={(newValue) => setDateEnd(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}

            {bookItem?.booking_type == 3 && (
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={t("time")}
                      value={timeEnd}
                      name="timeEnd"
                      onChange={(newValue) => setTimeEnd(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{t("vehicle")}</InputLabel>
                <Select
                  value={vehicle}
                  id="vehicle"
                  name="vehicle"
                  label={t("vehicle")}
                  onChange={(e) => setVehicle(e.target.value)}
                >
                  {modelApi.map((model, index) => (
                    <MenuItem key={index} value={model.id}>
                      {t(model.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="PERSONAS"
                fullWidth
                type="number"
                variant="outlined"
                value={persons}
                onChange={(e) => setPersons(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-basic"
                label="NOTAS"
                fullWidth
                variant="outlined"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Button
                fullWidth
                type="submit"
                color="error"
                variant="contained"
                size="large"
                sx={{ height: "100%" }}
                onClick={handleCloseEdit}
              >
                CERRAR
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                sx={{ height: "100%" }}
                onClick={(e) => updateBookingLater(e, bookItem)}
              >
                ACTUALIZAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default HistoryQuoteLater;
