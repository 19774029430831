import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Modal,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";

import Header from "../../../components/header/Header";
import TopMenu from "../../../components/topbar/TopMenu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import {
  getToken,
  decodeRefreshToken,
  decodeDataToken,
} from "../../../helpers/token/refreshToken";
import { tokens } from "../../../theme";
import { db } from "../../../firebase";
import { onValue, ref } from "firebase/database";
import { convertDate } from "../../../helpers/date/convertDate";
import { useTranslation } from "react-i18next";

const HistoryPendingNow = () => {
  const themeMui = useTheme();
  const theme = createTheme();
  const colors = tokens(themeMui.palette.mode);
  const [booking, setBooking] = useState([]);
  const { t } = useTranslation();
  const dataToken = getToken("dttkn");
  const { enterprise_id, sub_role } = decodeDataToken(dataToken);
  const currency = { 1: "PEN", 2: "USD" };

  useEffect(() => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);

    const starCountRef = ref(db, "BookingBusiness/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, a.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };
        //uid
        setBooking([]);
        Object.values(data)
          .sort(compareByDate)
          .map((_booking) => {
            if (sub_role === "ADMIN") {
              if (
                _booking.booking_type === 1 &&
                Number(_booking.enterpriseId) === Number(enterprise_id)
              ) {
                _booking.currency = currency[_booking.currency];
                _booking.fullorigin = _booking.origin.name;
                _booking.fulldestination = _booking.destination.name;
                setBooking((oldArray) => [...oldArray, _booking]);
                console.log(_booking);
              }
            } else if (_booking.booking_type === 1 && _booking.client === uid) {
              _booking.currency = currency[_booking.currency];
              _booking.fullorigin = _booking.origin.name;
              _booking.fulldestination = _booking.destination.name;
              setBooking((oldArray) => [...oldArray, _booking]);
              console.log(_booking);
            }
          });
      }
    });
  }, []);

  const model = ["", "Sedan", "SUV", "Minivan"];
  const [open, setOpen] = React.useState(false);
  const [uidX, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const generateOpenPayForm = async (booking) => {};

  const columns = [
    {
      headerName: t("number").toUpperCase(),
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: t("date").toUpperCase(),
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: t("time").toUpperCase(),
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: t("enterprise").toUpperCase(),
      field: "enterpriseName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: t("origin").toUpperCase(),
      field: "fullorigin",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: t("destination").toUpperCase(),
      field: "fulldestination",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      wrap: true,
    },
    {
      headerName: t("pack").toUpperCase(),
      field: "persons",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      headerName: t("notes").toUpperCase(),
      field: "note",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: t("Currency").toUpperCase(),
      field: "currency",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 70,
    },
    {
      headerName: t("state").toUpperCase(),
      field: "statusPayment",
      width: 90,
      renderCell: ({ row: { statusPayment } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              width: "100%",
              textAlign: "right",
              color: "white",
              background: statusPayment === "PENDIENTE" ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {statusPayment}
          </div>
        );
      },
    },
  ];

  const formatClientName = (name) => {
    const nameSplit = name.split(" ");
    if (nameSplit.length === 2) {
      return name;
    }
    if (nameSplit.length === 3) {
      return `${nameSplit[0]} ${nameSplit[1]}`;
    }
    if (nameSplit.length > 3) {
      return `${nameSplit[0]} ${nameSplit[2]}`;
    }
    return name;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("booking_pending")} />
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardHeader
              sx={{ mt: -1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  TM-{booking.counterBooking}
                </Typography>
              }
              action={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {booking.date} - {booking.time}
                </Typography>
              }
            />
            <CardContent sx={{ mt: -4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                {booking.driver ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Conductor: {booking.driver.name} {booking.driver.lastname}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Vehicle: {booking.driver.brand} {booking.driver.model} -
                      {booking.driver.plate}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {sub_role === "ADMIN" && (
                    <Typography
                      color={"primary"}
                      sx={{ fontSize: "13px", fontWeight: "bold" }}
                    >
                      {formatClientName(booking.clientName)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  {booking.payment_type == 1 && (
                    <p
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "100%",
                        fontSize: 15,
                      }}
                    >
                      {booking.currency} {booking.total}
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{ fontWeight: "bold", background: "#128C7E" }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {booking.total > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>
                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("NO HAY PEDIDOS PENDIENTES")}</div>
      )}
    </>
  );
};

export default HistoryPendingNow;
